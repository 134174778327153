<template>
    <div class="points-detailed" v-if="isLoad">
        <div class="title-small">积分明细</div>
        <template v-if="list.length > 0">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="到底啦〜想不到你看到了这里^_^"
                @load="getIntegralList"
            >
                <router-link
                    :to="'/PointsDetails?id=' + item.integral_id"
                    tag="div"
                    class="grid"
                    v-for="(item, index) in list"
                    :key="index"
                >
                    <img
                        :src="require('@/assets/' + (item.integral_types == 1?'jf03':'jf04') + '.png')"
                        alt
                    />
                    <div class="mid">
                        <div class="desc">{{ item.integral_notes }}</div>
                        <div class="time">{{ item.create_time }}</div>
                    </div>
                    <div
                        :class="['num',{'pink':item.integral_types == 2}]"
                    >{{ (item.integral_types == 1?'+':'') + item.integral }}</div>
                </router-link>
            </van-list>
        </template>
        <no-data v-else></no-data>
    </div>
</template>
<style lang="scss" scoped>
.points-detailed {
    padding: 0 3vw;
    background: #f6f6f6;
    min-height: 100vh;
    width: 100vw;
    box-sizing: border-box;

    .title-small {
        position: relative;
        height: 1rem;
        display: flex;
        align-items: center;
        font-size: 0.3rem;
        padding-left: 10px;
        background-color: #f6f6f6;
        &::before {
            position: absolute;
            left: 0;
            content: "";
            width: 0.04rem;
            height: 0.3rem;
            background: #ff7a01;
        }
    }

    .van-list {
        height: calc(100vh - 1rem);
        overflow-y: auto;
    }

    .grid {
        display: flex;
        align-items: center;
        position: relative;
        background: #fff;
        padding: 0.2rem;
        border-radius: 5px;
        margin-bottom: 10px;

        img {
            width: 0.75rem;
            height: 0.75rem;
            margin-right: 0.2rem;
        }

        .mid {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 0.75rem;

            .desc {
                font-size: 0.28rem;
            }

            .time {
                color: #d1d1d1;
                font-size: 0.24rem;
            }
        }

        .num {
            position: absolute;
            right: 0.2rem;
            font-size: 0.3rem;
            &.pink {
                color: #ff7a01;
            }
        }
    }
}
</style>
<script>
import { getIntegralList } from "@/http/api";
import { getIntegral } from "../../http/api";

export default {
    components: {},
    data() {
        return {
            list: [],
            isLoad: false,
            pages: 1,
            loading: false,
            finished: false
        };
    },
    mounted() {
        if ((this.userInfo = this.$login.isLogin(window.location.href))) {
            // this.getIntegralList();
        }
    },
    methods: {
        //获取积分明细列表
        async getIntegralList() {
            const res = await getIntegralList({
                data: {
                    user_id: this.userInfo.user_id,
                    pages: this.pages
                }
            });
            console.log(res);
            this.loading = false;
            this.isLoad = true;
            if (res.length > 0) {
                this.pages++;
                this.list = this.list.concat(res);
                this.finished = true;
            } else {
                this.finished = true;
            }
        }
    },
    computed: {},
    filters: {}
};
</script>
